<template>
  <Transition>
    <div class="system-toast" v-if="showSystemToast">
      <span class="material-symbols-outlined"></span>{{ showSystemToast }}
    </div>
  </Transition>
  <div class="block-ui" v-if="showTimeRemaining">
    <div class="logout-alert">
      <div class="notice">Due to inactivity you will be logged out soon.</div>
      <div class="button-container">
        <button @click.prevent="updateActivity" class="primary-filled-button">Stay
          logged in</button>
        <button @click.prevent="onSignOut" class="primary-outlined-button">Logout</button>
      </div>
    </div>
  </div>
  <iframe :src="targetOrigin" hidden id="op" width="0" height="0"></iframe>
  <ReportViewer @on-hide-viewer="hideViewer" :doc-type="docType" :response-value="reportData"></ReportViewer>
  <router-view :key="$route.fullPath" />
</template>

<script setup>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { onSignOut, parseJwt } from '@/common/shared.js'
import { keycloakBaseUrl, keycloakClientId, sessionTimeoutCheckSeconds } from '@/settings'
import { setBodyBackgroundColor } from './body-background-color.js'
import ReportViewer from './ux3/cards/ReportViewer/ReportViewer.vue'
import { check3pCookiesSupported } from '@/pages/Login/cookieCheck.js'

const store = useStore()

// Set the current time for checking later
localStorage.setItem('lastActivitySecondsSinceEpoch', Math.round((new Date().getTime()) / 1000))

watchEffect(() => setBodyBackgroundColor(store.getters.currentThemeName))

const language = computed(() => store.getters.profile.language)
const showSystemToast = computed(() => store.getters.showSystemToast)
const docType = computed(() => store.getters.reportDocType || '')
const reportData = computed(() => store.getters.reportDocument || '')

watch(language, () => {
  const bodyStyle = document.body.style
  if (language.value === 'zh_CN.UTF-8') {
    bodyStyle.fontFamily = '"Noto Sans SC", sans-serif'
  }
  else if (language.value === 'zh_TW.UTF-8') {
    bodyStyle.fontFamily = '"Noto Sans TC", sans-serif'
  }
  else {
    bodyStyle.fontFamily = '"Roboto", sans-serif'
  }
})

// countdown to logout
const showTimeRemaining = ref(false)
const timerFlag = ref(false)
const keycloakOrigin = new URL(keycloakBaseUrl).origin
const logoutAfterInactivityMinutes = parseInt(store.getters.featureToggles && store.getters.featureToggles.logoutAfterInactivityMinutes)
const INACTIVITY_TIMEOUT_MINUTES = 3
const initialTimeoutMilliseconds = Math.max(logoutAfterInactivityMinutes, INACTIVITY_TIMEOUT_MINUTES) * 60000
const threePTargetOrigin = `${keycloakBaseUrl}/protocol/openid-connect/3p-cookies/step1.html`
// checking for keycloak session state
const targetOrigin = `${keycloakBaseUrl}/protocol/openid-connect/login-status-iframe.html`
const opFrameId = 'op'


// reused function to see how long the user is inactive in minutes
const getInactivityMinutes = () => {
  const nowInSecondsSinceEpoch = Math.round((new Date().getTime()) / 1000)
  const inactivityInSeconds = Math.round(nowInSecondsSinceEpoch - localStorage.getItem('lastActivitySecondsSinceEpoch'))
  return inactivityInSeconds / 60
}

// Starts and maintains the visual logout warning modal
const logoutTimer = () => {
  const inactivityInMinutes = getInactivityMinutes()
  const timeRemainingMinutes = logoutAfterInactivityMinutes - inactivityInMinutes
  if (timeRemainingMinutes <= 0) { return onSignOut() }
  if (timeRemainingMinutes <= INACTIVITY_TIMEOUT_MINUTES) {
    timerFlag.value = true
    showTimeRemaining.value = true
  }
  setTimeout(logoutTimer, 10000)
}

// Check how long the page has been inactive, and if there are 3 min or less left, start timer
const checkInactivity = () => {
  if (!window.access_token) { return }
  const timeleft = calculateTimeRemainingMinutes()
  const decodedJwt = parseJwt(window.access_token)
  if (decodedJwt.exp < new Date().getTime() / 1000) { window.id_token = '' }
  if (timeleft <= INACTIVITY_TIMEOUT_MINUTES) { return logoutTimer() }
}

const setInactivityTimeout = () => {
  const timeleft = calculateTimeRemainingMinutes()
  if (timeleft > INACTIVITY_TIMEOUT_MINUTES) {
    setTimeout(checkInactivity, (timeleft - INACTIVITY_TIMEOUT_MINUTES) * 60000)
  } else {
    checkInactivity
  }
}

const calculateTimeRemainingMinutes = () => {
  const inactivityInMinutes = getInactivityMinutes()
  const timeRemainingMinutes = logoutAfterInactivityMinutes - inactivityInMinutes
  return timeRemainingMinutes
}

// set initial timeout to check for inactivity to check the time remaining
setInactivityTimeout()

// if the timer has been reset, set the toast, and reset the values
const updateActivity = () => {
  store.dispatch('showSystemToast', 'Session Extended')
  setTimeout(() => store.dispatch('showSystemToast', ''), 5000)
  localStorage.setItem('lastActivitySecondsSinceEpoch', Math.round((new Date().getTime()) / 1000))
  showTimeRemaining.value = false
  timerFlag.value = false
  setInactivityTimeout(initialTimeoutMilliseconds)
}

const hideViewer = () => {
  store.dispatch('showViewer', false)
  store.dispatch('reportDocType', '')
  store.dispatch('reportDocument', '')
}

onMounted(async () => {
  const threePResult = await check3pCookiesSupported(threePTargetOrigin)

  // when window regains focus after losing it, check if user is has leen logged out.
  document.body.onfocus = () => checkInactivity()

  // check the session state
  const checkSession = () => {
    if (!window.id_token) { return }
    const mes = keycloakClientId + ' ' + window.session_state
    const win = window.parent.frames[opFrameId].contentWindow
    win.postMessage(mes, targetOrigin)
  }

  // start interval to validate session - check set in environment variable
  if (threePResult) {
    setInterval(checkSession, sessionTimeoutCheckSeconds * 1000)
  }

  // when message is received back, sign out if need be, or produce a console error
  const receiveMessage = async (e) => {
    if (e.origin !== keycloakOrigin) { return }
    if (e.data === 'changed') {
      //clearInterval(timerID)
      return onSignOut()
    }
    if (e.data === 'unchanged') { return }
    if (e.data === 'error') {
      console.error('Received error response from check session iframe.')
      return
    }
    console.error(`Received unknown response from check session iframe: ${e.data}`)
  }

  // iframe message listener
  window.addEventListener('message', receiveMessage, false)

})

</script>

<style lang="scss">
@use "./node_modules/primeflex/primeflex.css";
@use "./node_modules/primevue/resources/themes/lara-light-blue/theme.css";
@use "./node_modules/primevue/resources/primevue.min.css";
@import '@/App.scss';
@import '@/common/tutorial.scss';
@import '@/common/scrollbar.scss';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'),
    url('./fonts/RobotoMono-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans SC';
  src: local('Noto Sans SC'),
    url('./fonts/NotoSansSC-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans TC';
  src: local('Noto Sans TC'),
    url('./fonts/NotoSansTC-Regular.otf') format('opentype');
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  background-color: var(--baseBackgroundColor);
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh; // Required to get tutorial to work!
}

.p-component,
.p-inputtext {
  font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
}

.p-inputtext,
.p-dropdown {
  font-size: 0.8rem;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--toolTextColor);
  position: relative;
}

#op {
  position: absolute;
}

.system-toast {
  /* Default Info Toast */
  z-index: 100000000;
  position: fixed;
  bottom: 3rem;
  margin: 0 auto;
  padding: 0.5rem;
  left: 0;
  right: 0;
  height: 4rem;
  width: fit-content;
  max-width: 50vw;
  border-radius: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 3px 3px 6px var(--Grey600);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--Grey200);
  border: 3px solid var(--Primary);
  color: var(--Primary);

  ::before {
    font-family: 'Material Symbols Outlined';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;
    content: '\e88e';
    margin-right: 8px;
  }
}

.toast-success {
  background-color: var(--SuccessGreenLight);
  border: 3px solid var(--SuccessGreenDark);
  color: var(--SuccessGreenDark);

  ::before {
    font-family: 'Material Symbols Outlined';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;
    content: '\e8dc';
    margin-right: 8px;
  }
}

.toast-error {
  background-color: var(--ErrorRedLight);
  border: 3px solid var(--ErrorRedDark);
  color: var(--ErrorRedDark);

  ::before {
    font-family: 'Material Symbols Outlined';
    content: '\e160';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;
    margin-right: 8px;
  }
}

.toast-warning {
  background-color: var(--WarningYellowLight);
  border: 3px solid var(--WarningYellowDark);
  color: var(--Black);

  ::before {
    font-family: 'Material Symbols Outlined';
    content: 'warning';
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;
    margin-right: 8px;
  }
}

.block-ui {
  position: fixed;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-alert {
  background-color: var(--White);
  color: var(--Black);
  border-radius: 16px;
  min-width: 30vw;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.notice {
  padding: 1rem;
  font-weight: 700;
}

.time-left {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 2rem;
  color: var(--errorTextColor);
  text-transform: capitalize;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.card-name {
  text-wrap: nowrap;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.p-datatable {
  border-radius: 6px;
  overflow: hidden;
}

.p-datatable .text-input {
  min-width: 100%;
}

.tab-count {
  display: flex;
  height: 15px;
  min-width: 15px;
  padding: 7px 5px;
  color: #fff;
  background-color: var(--Primary);
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  font-weight: 800;
  border-radius: 20px;
  margin-left: -3px;
  margin-top: -14px;
}

h3 {
  font-size: 1rem;
  color: var(--Grey800);
}

.p-dropdown .p-dropdown-label,
.p-input,
.p-inputtext,
.p-datetime .p-calendar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
}

.p-dropdown .p-dropdown-label.p-placeholder,
.p-input::placeholder,
.p-inputtext::placeholder,
.p-datetime::placeholder,
.p-calendar::placeholder,
select {
  color: var(--Primary);
}

.p-calendar .p-inputtext {
  flex: 1 1 auto;
  width: auto !important;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.4;
}
</style>
